ul.doc-list-bar{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    color: var(--txt-55-color);
    position: sticky;
    top:4.87rem;
    z-index: 2;
    background-color: #efefef;
    flex-wrap: wrap;
    
}
ul.doc-list-bar>li{
    padding:0.5rem 0;
}
ul.doc-list-bar li.r-pos{
    margin-left: auto;
}
.site-path{
    padding: 0;
    margin: 0;
}

.search-container {
    list-style-type: none;
    margin:0;
    padding:0;
    display:flex;
    flex-wrap: wrap;
    background-color:#e0e0e0;
    padding: 1rem 0.25rem;
}
.search-container >li{
    box-sizing: border-box;
    flex:1 1 100%;
    padding: 0 0.5rem;
}

.search-container label{
    display:block;
    font-size: 1rem;
}

.search-container input[type=text] {
    background-color: #fff;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 1rem;
    border: 1px solid var(--sec-color);
    margin-bottom: 0;
    width: 99%;
}
.search-container button {
    width:100%;
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0.4rem 0.6rem;
    border-radius: 0;
    margin-top: 1rem;
}
/*----- document item -------------*/
.doc-item{
    border-top:1px dotted var(--txt-70-color);
    margin:1rem 0;
    padding: 0;
    list-style-type: none;
    display:flex;
    flex-wrap: wrap;
}   
.doc-item:last-child {
    border-bottom:1px dotted var(--txt-70-color);
    padding-bottom: 1rem;
}
.doc-item > li{
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 0.25rem 0;
    flex:0 0 100%;
    font-size:1.1rem;
}
li.doc-description, li.doc-subject, li.doc-composer, li.doc-publisher {
    font-size: 1rem;
}
li.doc-composer, li.doc-poet, li.doc-publishert{
    font-style: italic;
    font-size: 1rem;
    color:var(--txt-dd-color);
}

li::before{
    content: attr(data-label);
    font-weight: bold;
    color:#000;
    font-size:1rem;
    font-style: normal;
    padding-right: 2px;
}
ul.detail li::before{
    display: inline-block;
    width:30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
li.doc-detail {
    flex:0 0 100%;
    display:flex;
    flex-wrap: wrap;
    align-content:space-between;
    
}
li.doc-detail > *{
    box-sizing: border-box;
}

li.doc-detail span.edit{
    flex:0 0 7%;
}
li.doc-detail span.right{
    margin-left: auto;

}
li.doc-detail span.left  span{
    font-size: 0.8rem;
}
.detail-content{
    flex: 0 0 100%; 
    /*padding-left: 7%;*/
    
}
ul.detail{
    background-color:transparent;/*#B37EAC17;*/
    list-style-type: none;
    padding: 0;
    margin-bottom: 0.5rem;
    overflow:hidden;
    /* max-height: 0; */
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-duration: 1.5s;
    animation-name: fade;
}
ul.detail li{
    padding: 0.2rem 0;
}
li.doc-detail span {
    color:var(--sec-color);
    cursor:pointer;
}
li.doc-detail span:hover{
    color:var(--pr-color);
}

/*------------------------------------ document Form -------------*/
.doc-form-container{
    display:flex;
    flex-direction: column;
}
.doc-form-container > div{
    margin:0.5rem 0;
}

span.flag-lang{
    margin-right: 0.5rem;
    font-family: TwemojiMozilla;
}
.sec-lang{
    font-size:0.9rem;
    font-style: italic;
}

input[type=text].doc-form{
    width:calc(100% - 1.5rem);
    font-size: 1rem;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
}
.rem-poet-details{
    box-sizing: border-box;
    border:1px dotted var(--txt-70-color);

}
span.poet-rem{
    display: inline-block;
    width:20%;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    font-size: 0.85rem;
    color:var(--txt-70-color);
    
}
label.option-lbl{
    display: inline-block;
    font-size: 0.75rem;
    width:33%;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    
}
input[type=text].poet-rem, input[type=text].option-des, input[type=text].option-name{
    box-sizing: border-box;
    margin:0;
    padding:0.2rem 0;
    border-bottom:1px solid #aaa;
    font-size: 0.9rem;
    width:calc(40% - 1.5rem);;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}

input[type=text].option-des{
    width:calc(50% - 1.5rem);
}

input[type=text].option-name{
    width:calc((100% - 2.5rem) / 3);
    margin-right:0.1rem;
}
button.add-option, button.cancel-option, button.submit-option{
    padding: 0.5rem 0.75rem;
    width:auto;
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: normal;
    margin-left:0.5rem;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

button.cancel-option{
    color:#f87171;
    background-color: #fef2f2;
    border:1px solid #f87171;
}
button.submit-option{
    color:#047857;
    background-color: #ecfdf5;
    border:1px solid #047857;
}


.option-form{
    background-color:#efefef;
    padding:1rem;
    box-sizing: border-box;
}

.info-box{
    box-sizing: border-box;
    line-height: 2rem;
    width:80%;
    padding: 1rem;
    position: fixed;
    margin: 0 auto;
    top: calc(50% - 3rem);
    left:10%;
    z-index:1000;
    text-align: center;
    box-shadow: 0 0 0 rgba(0,0,0,0.05);
}
.info-box .close-info {
    position: absolute;
    top:-0.5rem;
    right:-0.5rem;
    color:var(--txt-55-color);
    font-size: 1.5rem;
}

.update-part{
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.update-part > button{
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 0.75rem;
}

button.return-btn{
    background-color: transparent;
    color:var(--sec-color);
    font-weight: normal;
    padding: auto;
    
}
button.return-btn:hover{
    color: var(--pr-color);
}
/*--------------------document-file-path-----------------------------------*/
ul.file-path{
    margin:0.5rem 0 1.5rem 0;
    padding: 0;
    list-style-type: none;
}
ul.file-path > li{
    display:flex;
    margin: 0.75rem 0;
    flex-wrap: wrap;
    border:1px dotted  var(--txt-dd-color);
}
ul.file-path > li label{
    box-sizing: border-box;
    font-size:0.9rem;
    font-weight: normal;
    flex:1 2 30%;
    padding:0.2rem 0;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    
}
ul.file-path > li input[type=text]{
    box-sizing: border-box;
    font-size: 0.9rem;
    padding:0.2rem;
    flex:3 1 70%;
    margin:0;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
}


/* tablet */
@media screen and (min-width: 768px) {
    ul.doc-list-bar{
        top:4.9rem;
    }
    .search-container >li{
        flex:1 1 50%;
    }
    .search-container > li.srch-btn{
        align-self: flex-end;
        text-align: center;
        flex-basis: 40%;
     }
     .search-container button {
        width:50%;
       
    }
    /*---------------- document item --------*/
    .doc-item > li{
        font-size: 1rem;
    }
    li.doc-ID, li.doc-work-number {
       /* flex:1 2 7%;*/
       flex:0 0 7%;
    }
   
   
    li.doc-work-title, li.doc-series-title{
        /* flex: 2 1 40%; */
        flex: 0 0  43%;
        /* border:1px solid #777; */
    }
    
    li.doc-subtitle, li.doc-poet{
        padding-left: 7%;
        /* flex: 2 1 47%; */
        flex: 0 0 57%;
        /* border:1px solid #777; */
    }
    
    li.doc-description, li.doc-subject, li.doc-composer, li.doc-publisher {
        /* flex: 2 1 47%; */
        flex: 0 0 21.5%;
        /* border:1px solid #777; */
        font-size: 0.9rem;
        color:var(--txt-55-color);
    }
    
    li.doc-composer, li.doc-poet, li.doc-publishert{
        font-style: italic;
        font-size: 0.9rem;
        color:var(--txt-dd-color);
    }
    li.doc-orchestration{
        flex: 0 0 100%; 
        font-size: 0.9rem;
        padding-left: 7%;
    }
    
    li.doc-detail {
        align-content: flex-start;
    }
    .detail-content{
        /* flex: 0 0 100%;  */
        padding-left: 7%;
        
    }

    li::before{
        font-size:0.9rem;
    }
    /* ul.detail{
        animation-name: slide_tab;
    } */
    ul.detail li::before{
        width:20%;
    }

   
    /*------------------------------------ document Form tablet -------------*/
   
    ul.file-path > li{
        flex-wrap: nowrap;
        background-color: transparent;
        margin: 0;
    }
    ul.file-path > li{
        flex-wrap: nowrap;
        border:none;
    }
    
}
/* desktop */
@media screen and (min-width: 1000px) {
    ul.doc-list-bar{
        top:6rem;
    }
    .search-container {
        flex-direction: row;
    }
    .search-container > li{
        flex:1 1 33%;
        max-width: 33%;
    }

    .search-container > li.srch-btn{
       align-self: flex-end;
       text-align: right;
       flex-basis: 33%;
       max-width: unset;
    }
    .search-container button {
        width:25%;
        margin-top: 0;
        margin-right: 1rem;
    }
    
    li::before{
        font-size:0.75rem;
    }
    /* ul.detail{
        animation-name: slide_desc;
    } */
    ul.detail li::before{
      
        width:12%;
    }

    /*------------------------------------ document Form desktop-------------*/
    .doc-form-container{
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    .doc-form-container > div{
        flex-basis:52%;
       
    }
   
}

@keyframes slide_mob {
    from {opacity: 0;max-height: 0;}
    to{opacity: 1;max-height: 215px;}
}
@keyframes slide_tab {
    from {opacity: 0;max-height: 0;}
    to{opacity: 1;max-height: 200px;}
}
@keyframes slide_desc {
    from {opacity: 0;max-height: 0;}
    to{opacity: 1;max-height: 180px;}
}

@keyframes fade {
    from {opacity: 0}
    to{opacity: 1;}
}










