.admin-box{
    margin:0;
   
    /* border: 1px solid var(--sec-color); */
    
}
.admin-lnk{
    text-decoration: none;
    color:var(--sec-color);
    font-weight: bold;

}
.logout-lnk > label {
    display:none;
    color:inherit;
    margin-right: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
}
.logout-lnk > .logout-icon{
    font-size: 1.6rem;
}
.admin-lnk:hover, .ch-pass:hover{
    /* text-decoration: underline;   */
    color: var(--pr-color);
   
}

.flw{
    display:flex;  
    flex-wrap: wrap;
    gap:1rem;
    justify-content: space-between;
    /* align-items: flex-end; */
}
.flw input{
    min-width: 300px;
    width:95%;
}

.flw>div{
    flex: 1 1 80%;
    /* border:1px solid #000; */
}

.flw button{
    margin:0;
    min-width: 300px;
}
.hdfl{
    display:flex;  
    align-items: center;
}


.hdfl>span {
    margin-left: auto;
}
.hdfl h1 {
    font-size: 24px;
}
.flw h4.ch-pass{
    color:var(--sec-color);
    padding: 0.5rem 0.5rem 0.5rem 0;
    margin: 2.5rem 0 0 0;
    cursor: pointer;
    background-color: #efefef;
    /* border:1px dotted var(--sec-color); */
    /* border-bottom: none; */
    box-sizing: border-box;
   
}
.flw h4{
     padding: 0.5rem 0;
   /* margin: 1rem 0; */
}

.info-box-profile{
    position:relative;
    box-sizing: border-box;
    min-height: 4rem;
    margin:0 0 1rem 0;
    /* border:1px solid var(--sec-color); */
}
.close-info{
    position:absolute;
    right:-0.5rem;
    top:-0.5rem;
    color:#555; /*var(--err-color);*/
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.5rem;
}
.pass-box{
    box-sizing: border-box;
    /* background-color:#efefef; */
    /* border:1px dotted var(--sec-color); */
    border-top: none;
}

.sp-icon{
    position:relative;
    color:var(--sec-color);
    cursor: pointer;
    margin-left:0.2rem;
}

.sp-icon:hover{
    color:var(--pr-color);
}
.sp-icon:active{
    top:1px;
}





/* tablet */
@media screen and (min-width: 768px) {
    .logout-lnk > label {
        display:inline;
    }
    .logout-lnk > .logout-icon{
        font-size: 1.4rem;
    }
    .hdfl h1 {
        font-size: 32px;
    }
    .flw>div{
        flex: 1 1 45%;
    }
    .flw input{
        width:calc(100% - 3.5rem);
    }
    .flw button{
        width:calc(100% - 3.5rem);
    }

}


/* desktop */
@media screen and (min-width: 1000px) {
    .flw input{
        width:calc(100% - 4rem)
    }
    .flw button{
        width:50%;
    }
}


