select.lang{
    background-color: transparent;
    font-size: 1rem;
    font-family: 'TwemojiMozilla';
}
select.lang option{
    background-color: #fff;
    

}
.paragraph {
    border:1px dotted #555;
    padding:1rem 1rem 0 1rem;
    margin: 0.5rem 0;
}
.p-title{
    margin: 0.5rem 0;
    
}
.p-title span{
    font-family: 'TwemojiMozilla';
}

input.p-title, textarea.p-content {
    width:99%;
    font-size: 1rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
textarea.p-content {
    min-height: 100px;
    color: var(--txt-55-color);
    outline: none;
    border: 2px solid var(--sec-color);

}

ul.tool-bar-archive{
    list-style-type: none;
    display:flex;
    padding: 0;
    margin:0.25rem 0 0 0;
    background-color: #f5f5f5;

}
ul.tool-bar-archive li:last-child{
    margin-left: auto;
}
ul.tool-bar-archive li:first-child{
    margin-left: 0;
}
/*ul.tool-bar-archive li{
    padding: 0 1rem;
}
ul.tool-bar-archive li:first-child{
    padding-left: 0;
}*/

button.ll-tool-bar{
    background-color: transparent;
    font-size: 1rem;
    border:none;
    color:var(--sec-color);
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    margin: 0;
    border-radius: 2px;
    border: 1px solid transparent;
    
}
button.ll-tool-bar:hover{
    text-decoration: underline;
    border-color: var(--pr-color);
    color: var(--pr-color);
    
}


