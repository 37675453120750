.button-container{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
button.pg {
	padding: 0.5rem;
	border-radius: 5px;
	border-width: 1px;
	color: #fff;
	background-color: var(--sec-color);
	text-align: center;
	width: auto;
	font-size: 0.8rem;
	flex: 1 1 auto;
}
button.pg:disabled{
    background-color:#777;
	color:#333;
}

 button.current-page{
    background-color: var(--pr-color);
    color:#fff;
	flex: 1 1 auto;
}

@media screen and (min-width: 768px) {
	.button-container{
		justify-content: start;
		gap:0.5rem;
	}
	button.pg {
		
		flex: 0 0 auto;
	}
	
}
@media screen and (min-width: 1000px) {
	.button-container{
		justify-content: start;
		gap:0.1rem;
	}
}
