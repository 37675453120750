
/* mobile */
section {
    margin: 2rem auto 0 auto;
}
.login-frm{
    width:100%;
    display: flex;
    flex-direction: column;
}

.login-btn, .cancel{
    width:100%;
}
.login-btn-area{
    display: flex;
    flex-direction: column;
}
label {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 14.22pt;
    color: var(--txt-55-color);
    font-weight: bold;
    position:relative;
}
label.mandatory::before{
    position: absolute;
    color:#800000;
    content:'*';
    left: -0.75rem;
    top:-0.2rem;
}
input[type=text], input[type=password], input[type="date"], select{
    outline: none;
    padding: 0.4rem 0;
    margin-bottom: 1.5rem;
    border: none;
    border-bottom: 2px solid var(--sec-color);
    font-size: 1.5rem;
    color: var(--txt-55-color);
}
input[type=text]:focus, input[type=password]:focus{
    border-color: var(--pr-color);;
}

.err-msg, .no-err, .suc-msg, .inf-msg{
    min-height: 3rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    justify-content: center;
    /*align-items: center;*/
    margin-top:0;
}
.err-msg{
    border:1px solid #800000;
    background-color: lightpink;
    color:#800000;
}
.suc-msg{
    border:1px solid #005000;
    background-color: lightgreen;
    color:var(--txt-55-color);
}
.inf-msg{
    border:1px solid #000080;
    background-color: lightblue;
    color:var(--txt-55-color);
}
.no-err{
    /*border:2px solid #008000;
    background-color: #90ee9070;*/
    color:var(--sec-color);
}
.cancel, .signup{
    background-color: transparent;
    color:var(--sec-color);
    border: none;
    cursor: pointer;
    padding-left: 0.1rem;
}
.cancel:hover, .signup:hover{
    text-decoration: underline;
}
.signup{
    font-size: 1rem;
}

.icons {
    position: relative;
    padding: 0.3rem;
    color: var(--sec-color);
    font-size: 0.9em;
    bottom: -0.5rem;
    cursor:pointer;
    border: 2px solid transparent;
    top:-0.25rem;
}
.icons:hover{
    border-color:var(--sec-color);
    border-radius: 0.2rem;
}

.icons:active{
    bottom: -0.55rem;
    opacity: 0.75;
}
.pwd-icon{
    float:right;
}

.signup-prompt{
    text-align: center;
    margin-top: 2.5rem;
}

.link-like{
    display: inline;
    background-color: transparent;
    color:var(--sec-color);
    cursor: pointer;
    border: none;
    font-weight: normal;
    width: auto;
    margin-left: 0.5rem;
    
}

.link-like:focus{
    outline: 1px solid var(--sec-color);
}

.link-like:hover{
    text-decoration: underline;
    background-color: transparent;
    color:var(--sec-color);
}

.info{
    font-size: 0.8rem;
    font-weight: bold;
    color:var(--txt-55-color);
    margin:1rem 0;
}
.info span{
    color:#800000;
    font-size:1.2rem;
}





/* tablet */
@media screen and (min-width: 768px) {
    section {
        /*margin:0 auto;*/
        width:80%;
    }
    .login-btn, .cancel {
        max-width: 50%;
    }
    .login-btn-area{
        flex-direction: row;
        align-items: center;
    }
    .signup-prompt{
        text-align: left;
       
    }
    
    
}

/* desktop */
@media screen and (min-width: 1000px) {
    
    section{
        max-width: 1000px;
        margin: 2rem auto 0 5.2rem; 
    }
    .login-frm{
        width:50%;
        /*border:1px solid red;*/
        
    }
    .login-btn-area{
        flex-direction: row;
        align-items: center;
    }
    .login-btn, .cancel {
        margin-left:auto;
    }
    .signup-prompt{
        text-align: right;
    }
    

}