div.admin-info{
    margin-left: auto;
    align-self: center;
    font-weight: bold;
    font-size: 0.8em;
}

.admin-name{
    color: var(--sec-color);
}
.admin-des{
    display:none;
    padding-right: 1rem;
}

.admin-dashboard{
    flex-direction: column;
    display: flex;
    flex-wrap: wrap; 
    /* border:1px dotted #000; */
    justify-content: space-between;
    width:100%;
    gap:1rem;
    
}
.dashboard-item{
    border: 2px solid var(--sec-color);
    border-radius: 1rem;
    text-align: center;
}

.dashboard-icon {
    padding: 0.5rem;
    font-size: 7rem;
}
.link{
    color:var(--sec-color);
    text-decoration: none;
}
.link:hover{
    text-decoration: underline;
    font-weight: bold;
}
.link-des{
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 0.5rem;
}


.search-bar {
 box-sizing: border-box;   
 display:grid;
 grid-template-columns: 1fr;
 grid-template-rows: repeat(8, 1fr);
 grid-auto-rows: 1fr;
 background-color: #e0e0e0;
 padding-bottom:0.5rem;
}
.search-button, .field-content, .search-status, .field-expire{
    box-sizing: border-box;
    padding: 0 1rem;
}
.search-button{
    grid-row: -2 / -1;
    
    align-self: end;
}

.search-bar label{
    display:block;
    font-size: 1rem;
}

.search-bar select, .search-bar input[type=date], .search-bar input[type=text] {
    background-color: #fff;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 1rem;
    border: 1px solid var(--sec-color);
    margin-bottom: 0;
    width: 99%;
}
.sm-width{
    width: 50%;
}

.search-bar button {
    width:100%;
    font-size: 0.9rem;
    font-weight: normal;
    padding: 0.4rem 0.6rem;
    border-radius: 0;
    margin-left: auto;
}
.icon-btn{
    color:var(--sec-color);
    border:1px solid var(--sec-color);
    font-size: 1.1rem;
    padding: 0.4rem;
    cursor:pointer;
    position: relative;
    top:-1px;
    
}
.icon-btn:hover{
    background-color: var(--sec-color);
    color:#fff;
}
.icon-btn:active{
    background-color: var(--pr-color);
    top:0;
}
.child{
    display:inline-block;
    width:40%;
    position: relative;
}
.child::before {
    content: "ID";
    display: inline-block;
    width:40%;
    background-color: dimgray;
    color:#fff;
}

.active-user, .inactive-user, .pending-user{
    position:relative;
    color:#597211;
    font-size: 0.8rem;
    padding-left: 1rem;
}
.inactive-user{
    color:red;
}
.pending-user{
    color:orange;
}

.active-user::before, .inactive-user::before, .pending-user::before {
    content:'';
    position: absolute;
    top:0.2rem;
    left:0;
    width:0.5rem;
    height: 0.5rem;
    background-color: #597211;
    border-radius: 50%;
}
.inactive-user::before {
    background-color: #800000;
}
.pending-user::before {
    background-color: orange;
}

.user-symb{
    float: left;
    font-size: 0.8rem;
    color:var(--sec-color);
    margin-right: 0.2rem;
}

ul.users-bar{
    list-style-type: none;
    margin:0;
    padding: 0;
    display:flex;
    color:var(--txt-55-color);
}
ul.users-bar li{
    margin-right: 0.5rem;
}
ul.users-bar li.r-pos{
    margin-left:auto;
}

a.usr-lnk{
    color:var(--sec-color);
    text-decoration: none;
    font-weight: 700;
}
a.usr-lnk:hover{
    text-decoration: underline;
    color:var(--pr-color);
}

a.new-lnk{
    position: relative; 
    
    color:var(--sec-color);
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 4px;
    top:0;
    left:0;
}
a.new-lnk:hover{
    color:var(--pr-color);
}
a.new-lnk:active{
    top:-1px;
    left:-1px;
   
}
.eraser{
    color:var(--sec-color);
    cursor: pointer;
}

.eraser:hover{
    color: var(--pr-color);
}

/*   table smartphone */
.user-table{
    width:100%;
}
.user-table tr{
    display:block;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--sec-color);
}
.user-table tfoot tr{
    border-bottom: none;
}
.user-table td, .user-table th{
    display:block;
}
.user-table tbody td{
    position:relative;
    border-bottom: 1px dotted var(--sec-color);
    padding: 0.5rem 0.2rem 0.5rem 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.user-table tbody td::before {
    position: absolute;
    content:attr(data-label);
    left:0;
    font-size: 0.8rem;
    color:var(--txt-55-color);
    width:50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-table .tbl-head{
    display:none;
}

.del-icon{
    color:var(--sec-color);
    cursor: pointer;
}
.del-icon:hover{
    color: var(--pr-color);
}


/* tablet */
@media screen and (min-width: 768px) {

   
   
    .admin-des{
        display:inline;
    }
    .admin-dashboard{
        flex-direction: row;
        
    }   
    .dashboard-item{
        flex:1 1 45%;
    }
    .search-bar{
        grid-template-columns: repeat(3, 2fr) 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0 5px;
    }
    
    .tel{
       grid-column: 2/1; 
       grid-row: 2/3; 
    }
    .search-button{
        grid-row: 2/3;
        grid-column: auto/-1;
        align-self: end;
    }

    .search-button, .field-content, .search-status, .field-expire{
        box-sizing: border-box;
        padding: 0 0.2rem;
    }

    /*------------------------- table ------------------*/
    
    .user-table{
        width:100%;
        border-collapse: collapse;
        font-size:0.8rem;
    }

    .user-table tr{
        display:table-row;
    }
    .user-table td{
        display:table-cell;
    }
    
    .user-table th, .user-table .tbl-head{
        display:table-cell;
    }

    .user-table thead {
        background-color: var(--sec-color);
        /*border:1px solid var(--sec-color);*/
        position: sticky;
        top:4.92rem;
        z-index: 1;
    }

    .user-table thead th{
        color:#fff;
        padding: 0.5rem 0;
        text-align: left;
    }

    .user-table tbody tr {
        border-bottom: 1px solid var(--sec-color);
    }
    /*.user-table tbody{
        border:1px solid var(--sec-color);
    }*/
    .user-table tbody tr td{
        padding:1rem 0.1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .user-table tbody td::before{
        content: none;
    }

    

    
}


/* desktop */
@media screen and (min-width: 1000px) {
    .admin-des{
        display:inline;
    }
    .dashboard-item{
        flex:1;
    }

    .user-table{
        font-size:1rem;
    }

    .user-table thead {
        top:6rem;
    }

    .search-bar{
       grid-template-columns: repeat(4, 2fr) 1fr;
       grid-template-rows: 1fr 1fr;
       gap: 0 5px;
    }
    
    .search-status{
        grid-column: 1/2; 
        grid-row: 2/3; 
     }

     .tel{
        grid-column: 4/5; 
        grid-row: 1/2; 
     }
    .search-button{
        grid-row: 2/3;
        grid-column: auto/-1;
        align-self: end;
        width:4rem;
    }

   
    .user-table tbody tr td{
        padding:1.2rem 0.1rem;
        
    }
    

    

    
    
   
}