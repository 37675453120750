.drop-zone{
    border:2px dashed #000060;
    text-align: center;
    font-size: 1.5rem;
    padding: 4rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  .drop-zone:hover{
    background-color: #efefef;
  }
  
  .active-dz{
    border:3px dashed #000040;
    font-weight: bold;
  }
  .progress, .progress:hover{
    color:red;
    cursor: not-allowed;
    background-color: mistyrose;
    border-color: red;
  }
  
  .file-list{
    list-style-type: none;
    margin:0 0 2rem 0;
    padding:0;
    
    
  }
  .file-list li {
    
    padding:0;
    margin: 0;
    display:flex;
    flex-direction: column;
  }
  .file-list li:hover{
    background-color: #f5f5f5;
  }
  
  .progress-active{
    font-weight: bold;
    color: var(--sec-color);
  }
  .done {
    color:forestgreen;
  }
  .file-link{
    text-decoration: none;
    color: inherit;
  }
  .file-link:hover{
    text-decoration: underline;
    color:var(--sec-color);
  }
  
  .file-name, .progress-bar, .file-size{
    line-height: var(--lh);
  }
  .file-name{
    width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .file-size{
    box-sizing: border-box;
    width:100%;
    font-size: 0.8rem;
    padding-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .del-file{
    box-sizing: border-box;
    font-size:1rem;
    color:var(--sec-color);
    cursor: pointer;
    padding: 0.3rem;
    border:1px solid transparent;
    border-radius: 5px;
  }
  .del-file:hover{
    color: var(--pr-color);
    border:1px solid var(--pr-color);
  }
  
  .progress-bar{
    width:100%;
    border:2px solid #a0a0a0;
    position:relative;
    background-color: #f0f0f0;
    border-radius: 5px;
    height: var(--lh);
  }
  
  .bar{
    position: absolute;
    top:1px;
    left:0;
    height:calc(var(--lh) - 2px);
      border-radius: 5px;
    background-color: var(--sec-color);
    opacity: 0.75;
  }
  
  .size-pc{
    position: absolute;
    line-height: var(--lh);
    color:#000;
    width:20%;
    text-align: center;
    top:0;
    left:40%;
    font-weight: bold;
    
  }

  @media screen and (min-width: 768px) {
    /* .file-list{
      margin: 0;
    } */
    .file-list li {
      flex-direction: unset;
    }
    .file-name, .progress-bar, .file-size{
      line-height: var(--lh)
    }
    .file-name{
      width:40%;
      
    }
  
    .file-size{
      width:30%;
      
    }
  
    .progress-bar{
      width:30%;
      height:var(--lh);
    }
  
    .bar{
      position: absolute;
      top:1px;
      left:0;
    }
  
    .size-pc{
      position: absolute;
      
    }
  }