.zip-wrapper{
    display: flex;
    flex-direction: column;
}
.zip-btn, .zip-progress-bar-container {
    width:99%;
    margin-top:1rem;
    font-size: 1.2rem;
}

.zip-btn:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #777777;
    cursor: not-allowed;
}
.zip-spin {
    float: right;
    margin: 0.2rem 1rem;
    color:#000;
}

.zip-message {
    color:var(--txt-55-color);
    line-height: 1.2rem;
    margin: 1rem auto;
}
.zip-progress-bar-container{
    position:relative;
    border: 1px solid #999999;
    background-color: #cccccc;
    min-height: 1.5rem;
    overflow: hidden;
}
 .zip-progress-bar{
    position:absolute;
    top:0;
    left:0;
    height: 100%;
    background-color: var(--sec-color);
    
 }
 .zip-percent{
    position: absolute;
    width:100%;
    z-index: 1;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color:#fff;
    line-height: 1.5rem;

 }
/*---- tablet ------*/
 @media screen and (min-width: 768px) {
    .zip-wrapper{
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
    }

    .zip-btn {
        width :45%;
    }
    .zip-message{
        margin-top: 0.2rem;
        font-size: 0.9rem;
    }

 }

 /*---- Desktop - laptop ------*/

 @media screen and (min-width: 1000px) {
    .zip-btn {
        width :33%;
    }
 }





