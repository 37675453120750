main {
    margin : 0 auto;
    
}
h4{
    font-weight: bold;
    font-size: 12.64pt;
    color:var(--txt-dd-color);
}

.content-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border:none;
    
}
.content-form > div {
    width:100%;
}
div.btn-cont{
    margin: 4rem 0 2rem 0;
    text-align: center;

    
}
button {
    width:90%;
    background-color: var(--sec-color) ;
    font-size: 14.22pt;
    font-weight: bold;
    color:#fff;
    padding: 0.75rem  0;
    border-radius: 0.5rem;
}
button:hover{
    background-color: var(--pr-color) ;
    color:#fff;
}

.img-disp{
    margin-right: 0.5rem;
   
}


/*------------------ Catalog ----------------------- */
.content-catalog{
    display: flex;
    flex-direction: column;
    max-width:none;
    width: auto;
    
}
.alphabet-selector, .letter{
    font-size:18pt;
    color:var(--sec-color);
    font-weight: bold;
    padding:1rem 1rem 1rem 0;
    margin-right: 1rem;
    cursor: pointer;
    background-color: transparent;
    width:auto;
    border:none;
    outline: none;
    position: relative;
    top:0;
}
.alphabet-selector:hover, .letter:hover{
    background-color: transparent;
    color:var(--sec-color);
}

.letter{
    font-size:20pt;
    padding: 0;
}
.alphabet-selector:active, .letter:active{
    top:2px;
}
.selected, .selected:hover{
    color:var(--pr-color);
}
.load-sign{
    height: 2rem;
    margin-top: 1rem;
}
/*catalog composer */
ul.composer-content{
    list-style-type: none;
    margin:2rem 0;
    padding: 0;
}
ul.composer-content > li.composer {
    padding: 0;
    margin:0;
    display:flex;
    margin-bottom: 1rem;
    justify-content: space-between;
}
ul.composer-content > li.composer:last-child{
    margin-bottom: 0;
}
ul.composer-content > li.composer::before, ul.composer-content > li.composer::after{
    padding: 0;
    margin: 0;
}
.composer-name{
    flex:1;
    align-self: center;
    border-bottom: 1px dotted var(--txt-55-color) ;
    font-size: 18pt;
    color:var(--txt-55-color);
}
.composer-link{
    color:var(--txt-55-color);
    text-decoration: none;
}
.composer-link:hover{
    text-decoration: underline;
}
.composer-works{
    background-color: #e0e0e0;
    color:#000;
    border-radius:50%;
    font-size: 14pt;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 2.5rem;
    width: 2.5rem;
}

.catalog-rtn{
    background-color: transparent;
    width:auto;
    color:var(--sec-color);
    border: none;
    font-weight: normal;
}
.catalog-rtn:hover{
    background-color: unset;
    color:var(--pr-color);
    text-decoration: underline;
    cursor: pointer;
}


/* tablet */
@media screen and (min-width: 768px) {
    div.btn-cont{
        width:50%;
        margin-left: auto;
        text-align: right;
       
    }

    .img-disp{
        margin: unset;
        margin-right: 0.5rem;
        display: inline;
       
    }
    button {
        width:90%;
    }

    .content-catalog{
        max-width:none;
        width: auto;
    }
    
    
}

/* desktop */
@media screen and (min-width: 1000px) {
  
    .content-form > div {
        width:45%;
       /* margin-left: auto;*/
    }
    div.btn-cont{
        width:30%;
        text-align: right;
    }

    .img-disp{
        margin: unset;
        margin-right: 0.5rem;
        display: inline;
        
    }
    button {
        width:90%;
    }
    .content-catalog{
        max-width:none;
        width: auto;
        margin: 0;
    }
    .alphabet-selector{
        margin-right: 2rem;
    }

    
    

}