.doci-div {
   /*  border :1px solid #555555; */
    margin-top: 1rem;
    padding: 0.5rem 0;
    display: flex;
    background-color: #fafafa;
}

.doci-content{
    flex:1;
    margin-left: 1rem;
    
}

.doci-content > div{
    padding: 0.1rem 0;
}
.doci-file{
    flex-basis: 70px;
    align-self: flex-start;
    position: relative;
}
.doci-link{
    margin:0;
    padding:0;
    border:none;
    outline: 0;
    background-color: transparent;
    width:unset;
    border-radius:0;
    cursor:pointer;
    color:var(--txt-70-color);
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.5rem;
}
.not-available{
    cursor: not-allowed;
    height: 92px;
    border: 1px dotted var(--txt-70-color);
    margin-bottom: 0.5rem;
}
.no-link{
    cursor: not-allowed;
}
.doci-link:hover{
    background-color: unset;
    color:var(--txt-70-color);
}
.sheet-music{
   /*  filter: hue-rotate(230deg);   */
    filter: grayscale(70%);
    object-fit: contain;
    width:100%; 
}
.sheet-music:hover{
    filter: grayscale(50%);
}
.doci-lock{
    color:var(--txt-70-color);
    position: absolute;
    top:0.2rem;
    left:0.2rem;
    font-size: 1.2rem;
}
.doci-id{
    padding: 0.1rem 0.5rem;
    background-color:#e0e0e0 ;
    border-radius: 0.3rem;
    color:var(--txt-55-color);
    font-size: 0.7rem;
    font-weight: 700;
    cursor: pointer;
}
.doci-id:hover{
    color: var(--pr-color);
}

.doci-description{
    font-size: 1rem;
   font-weight: 500;
    color:var(--pr-color);
}
.doci-size-gr, .doc-size-gb{
    margin-left: 0.5rem;
}
.doci-size-gr::before, .doci-size-gb::before {
    content: 'σελ.: ';
    color:var(--txt-55-color);
    font-size: 0.8rem;
}
.doci-size-gb::before {
    content: 'pp.: ';
}
.doci-work-title{
    font-size: 18pt;
    font-weight: bold;
    color:var(--txt-55-color);
    cursor: pointer;
}
.doci-work-title:hover{
    text-decoration: underline;
    
}
.doci-subtitle{
    font-weight:normal;
    font-size:14pt;
    color:var(--txt-dd-color);
    margin-left: 1rem;
}
.doci-subject{
    font-size: 0.8rem;
    font-style: italic;
}
.doci-orch{
    color:var(--txt-dd-color);
    font-size: 1rem;
}

.more-less{
    font-size:0.85rem;
    color:var(--sec-color);
    cursor: pointer;
    font-weight: 500;
}
.more-less:hover{
    color:var(--pr-color);
}
.doci-detail-list{
    margin:0;
    padding:0;
    list-style-type: none;
}
.doci-detail-list > li{
    margin:0;
    padding:0;
    border-bottom: 1px solid var(--pr-color);
}
.doci-detail-list > li:first-child{
    border-top: 1px solid var(--pr-color);;
}
.doci-detail-title{
    font-family: 'Roboto', Arial, Helvetica, sans-serif;;
    font-size:14pt;
    color:var(--txt-55-color);
    font-weight: 500;
}
.doci-detail-text{
    font-family: 'Roboto', Arial, Helvetica, sans-serif;;
    font-size:16pt;
    color:var(--txt-dd-color);
    line-height: 1.5em;
}

@media screen and (max-width: 345px) {
    .doci-div {
        flex-direction: column;
    }
    .doci-content, .doci-subtitle, .doci-size-gb,.doci-size-gr {
        margin-left: 0;
    }
    .doci-content > div{
        display: flex;
        flex-direction: column;
    }
    .doci-file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        width: 100%;
    }
    
   
    .sheet-music{
        /*  filter: hue-rotate(230deg);   */
         filter: grayscale(70%);
         object-fit: contain;
         width:50px ; 
     }
    
   
  }