
html, 
body {
  margin: 0;
  height: 100%;
  --st-button-primaryColor: #B37EAC;
}

body{
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  
}
  

:root{
  box-sizing: border-box;
  --pr-color: #683d62;
  --sec-color:#B37EAC;
  --txt-d-color:#33230C;
  --txt-55-color:#555555;
  --txt-70-color:#707070;
  --txt-dd-color:#0A0609;
  --err-color: #e4002b;
  --suc-color: #597211;
  --warn-color: #F3894B;
  --info-color: #76C6D6;/*#B3DEEB;*/
  --lh:1.5rem;
}
.book-dark, .book-light{
  font-size: 1.5rem;
}
.book-dark{
  color:var(--pr-color);
}
.book-light{
  color:var(--sec-color);
}




@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/Roboto-Thin.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url("./fonts/Roboto-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url("./fonts/Roboto-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/Roboto-Italic.ttf") format("truetype");
}



@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url("./fonts/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/Roboto-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: bold;
  src: url("./fonts/Roboto-BoldItalic.ttf") format("truetype");
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url("./fonts/Roboto-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'TwemojiMozilla';
  src: url('./fonts/TwemojiMozilla.ttf');
}

/* --------------- slider -------------------*/
.slider-range {
  appearance:none;
 -webkit-appearance:none;
  width: 50%;
  height: 25px;
  background: #dfdfdf;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-range:hover {
  opacity: 1;
}

.slider-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--sec-color);
  cursor: pointer;
}

.slider-range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: var(--sec-color);
  cursor: pointer;
}
/* --------------------- check box -------------------------------- */
.lbl-chk{
  position:relative;
  display:inline-block;
  cursor: pointer;
  padding:5px 0 5px 35px;

  user-select: none;
  -webkit-user-select:none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: 0;
  font-weight: normal;
}


.lbl-chk input[type=checkbox]{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width:0;
}

.checkmark{
  position: absolute;
  top:0;
  left:0;
  height: 25px;
  width:25px;
  border:1px solid var(--txt-dd-color);
  border-radius: 0.2em ;
}
.lbl-chk:hover input[type=checkbox] ~ .checkmark{
  background-color: #eee;
}
.lbl-chk input[type=checkbox]:checked ~ .checkmark{
  background-color:var(--sec-color); /* ------------------------------------- */
}

.checkmark::after{
  content: "";
  position:absolute;
  display:none;
}

.lbl-chk input[type=checkbox]:checked ~ .checkmark::after{
  display: block;
}

.lbl-chk .checkmark::after{
  left:9px;
  top:5px;
  width:5px;
  height:10px;
  border:solid #fff;
  border-width:0 3px 3px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

/*---------------------switch check box on off ------------------------- */
.switch {
  position: relative;
  display: inline-block;
  width: 3.3rem;
  height: 1.5rem;
  
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
  
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #555;
  -webkit-transition: .4s;
  transition: .4s;
  font-size:9pt;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 3px;
  bottom: 0.25rem;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--sec-color);/*purple;*/
}
input:disabled + .slider {
  background-color: #000;
}
input:disabled + .slider:before {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
  
}

input:checked + .slider:after{
position: absolute;
  content: "ON";
  color:#fff;
  left:0.3rem;
  top:0.3rem;
}
input + .slider:after{
position: absolute;
  content: "OFF";
  right:0.25rem;
  color:#fff;
  top:0.3rem;
}
input:checked + .slider:before {
  -webkit-transform: translateX(2rem);
  -ms-transform: translateX(2rem);
  transform: translateX(2rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2rem;
}

.slider.round:before {
  border-radius: 50%;
}





