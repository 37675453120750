.summary{
    font-size: 1rem;
    margin-bottom:0.5rem;
    font-style: italic;
    color:var(--txt-70-color)
}
.opt-info-box{
    opacity: 0.8;
    color: var(--txt-dd-color);
    margin-left: 2rem;
    text-align: center;
    flex:1;
}
.suc{
    background-color: lightgreen;
}
.err{
    background-color: lightpink;
}
.no-act{
    background-color: lightcyan;
}
.opt-container{
    display:flex;
    /* align-items: center; */
    background-color: #f8f8f8;
    margin:0.5rem 0;
}
.opt-content-place{
    flex:1;
}
.opt-content{
    display:flex;
}
.spinner{
    color:var(--sec-color);
}
.opt-action, .opt-flag, .spinner{
    width:fit-content;
    font-size: 1.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
}
.opt-flag{
    font-family: TwemojiMozilla;
}
.opt-action{
    width:auto;
    border:none;
    background-color: transparent;
    outline: none;
    color: var(--sec-color);
    align-self: start;
    position: relative;
    cursor:pointer;
}
.opt-action:hover {
    background-color: transparent;
    color:var(--pr-color);
}
.opt-action:active{
    top:1px;
}

.opt-action:disabled {
    color: #d0d0d0;
    cursor: not-allowed;
}
.opt-action:disabled:active {
    top:0;
    cursor: not-allowed;
}

.opt-del{
    min-width: 4.4rem;
}
.opt-id{
    
    font-weight: normal;
    font-size: 0.8rem;
    color:var(--txt-70-color);
    text-align: center;
    margin-top:1.5rem;
}

.opt-srch-inp{
    width:92%;
}

.opt-counter{
    font-size: 1rem;
    box-sizing: border-box;
    background-color: #b8b8b8;
    border-radius: 4px;
    color:#fff;
    font-weight: bold;
    align-self: flex-start;
    width: fit-content;
    padding: 0.2rem;
    margin-right: 1rem;
    min-width: 3rem;
    text-align: center;
}


.opt-data{
    display:flex;
    flex-wrap:wrap;
    flex:1;
    padding: 0.5rem 0 0.1rem 0;
}
.opt-data > *{
    flex:1 1 100%;
}
.opt-fieldset {
    margin-bottom: 0.5rem;
}

.opt-data h3, .opt-data label{
    display:block;
    margin:0;
    padding:0 0 0.2rem 0;
    font-size: 1rem;
    font-weight: bold;
    color:var(--txt-55-color);
}

.opt-data input[type='text'], .opt-data .opt-value{
    color:var(--txt-dd-color);
    font-size: 1.1rem;
}
.opt-data input[type='text']{
    width: calc(100% - 0.5rem);
    border-bottom:1px solid var(--sec-color);
    padding-left:0.5rem;
    box-sizing: border-box;
}
.opt-value{
    line-height: 2rem;
}

.opt-srch-icon{
    display: inline;
}

@media screen and (max-width: 250px) {
    .opt-srch-icon, .opt-counter{
        display: none;
    }
}



@media screen and (min-width: 768px) {
    .opt-data > *{
        flex:1 1 33%;
    }
    .opt-fieldset{
        margin: 0;
    }
    .opt-srch-inp{
        width:97%;
    }

}

@media screen and (min-width: 1000px) {
    .opt-srch-inp{
        width:30%;
    }
}