
.container{
  width:95%;
  max-width:1200px;
  margin:0 auto;
  height: 100%;
}

.over-fl{
  height: 100vh;
  overflow: hidden;
}

header{
  display:flex;
  justify-content: space-between;
  /*border:1px solid #000;*/
  position: sticky;
  top:0; 
  padding-top: 0.5rem;
  z-index: 10;
  background-color: #fff;
  margin-bottom: 35px;
}


.logo {
  display:flex;
  align-items: center;
}



.logo h3, .logo h4{
  font-family: 'Roboto';
  margin:0 0 0 0.75rem;
  padding:0;
  font-weight: 700;
  
}

.logo h3{
  color: var(--pr-color);
  line-height: 1;
  
}
.logo h4{
  color: var( --txt-dd-color);
}


.menu-image{
  font-size:1.5rem;
  align-self: end;
  min-width: 1.8rem;
  
}

.profile-image{
  margin-left: auto;
  margin-right:2rem;
}

.user{
  color:var(--pr-color);
  margin-left: auto;
  margin-right: 0.5rem;
  margin-bottom:0.4em ;
  
}
.exit{
  font-size:2rem;
  cursor: pointer;
  margin-bottom:0.2em ;
}
.bars{
  font-size:2rem;
  cursor: pointer;
  margin-bottom:0.2em ;
}

.rest {
  align-self: flex-end;
}
nav{
  width:100%;
  position: absolute;
  left:0;
  top:5rem;
  background-color:var(--pr-color);;
  color:#fff;
  height: calc(100vh - 5rem);
  display: none;
  z-index: 10;
}
.open {
  display:block;
}

nav ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
nav ul li{
  padding: 0.75rem 0.5rem 0 0.2rem;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: right;
  box-sizing: border-box;
}

nav ul a:hover {
  color: var(--sec-color);
}
/*nav ul li:active {
  background-color: var(--sec-color);
}*/
nav ul.lang li{
  border-right: 3px solid #fff;
  width:4.2rem;
  cursor: pointer;
}
nav ul.lang li.active{
  text-decoration:underline;
  text-decoration-thickness: 2px;
}
.list-container{
  display: flex;
  justify-content: space-between;
  
}

.lnk {
  color:inherit;
  text-decoration: none;
  font-size: 18pt;
  padding:0.5rem;
  cursor: pointer;
}


.logout-lnk{
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  color:var(--sec-color);
  cursor: pointer;
}
.logout-lnk:hover{
  text-decoration: underline;
  color:var(--pr-color);
}


footer {
  border-top:1px solid var(--pr-color);
  margin:2rem auto;
  padding-top:2rem;
}
.social{
  display:flex;
  width:100%;
  justify-content: center;
  font-size: 2rem;
  color:var(--sec-color) ;
  margin:0;
  column-gap: 3rem;
  
}

.footer-logos{
  display:flex;
  flex-direction: column;
}
.group-left{
  order: 1;
}


.copyright{
  width: 100%;
  text-align: center;
  font-size: 12pt;
  font-weight: bold;
  color:var(--pr-color);
  margin: 1rem 0 0.5rem auto;
}

.design{
  width: 100%;
  text-align: center;
  font-size: 10pt;
  font-weight: normal;
  color:var(--pr-color);
  margin: 0.5rem 0 1rem auto;
}
a.ext-link{
  text-decoration: none;
  color:var(--sec-color);
}
a.ext-link:hover{
  color:var(--pr-color);
}
.sponsors{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sponsors img {
  height:76px;
}
.yppo-logo{
  margin-left: 1.4rem;
}



@media screen and (max-width: 345px) {
  .logo-des{
    display:none;
  }
  .profile-image{
    margin-left: auto;
    margin-right:0;
  }
  
  /*nav ul li {
    font-size: 0.8rem;
  }*/
}

@media screen and (min-width:768px){
  .footer-logos{
    flex-direction: row;
    justify-content: space-between;
  }
  .group-left{
    order: 0;
  }
  .social {
    justify-content: flex-start;
  }
  .copyright{
    text-align: left;
  }
  .design{
    text-align: left;
  }
  .sponsors{
    flex-direction: row;
  }
}
  

@media screen and (min-width: 1000px) {

  .logo-des{
    min-width: 220px;
  }
  
  nav{
    display: block;
    position: relative;
    background-color: transparent;
    color:var(--pr-color);
    height: unset;
    top:unset;
  }
  nav ul {
    display:flex;
  }

  .list-container{
    justify-content: space-evenly;
    margin-top: 2.5rem;
    flex-direction: row-reverse;
    }
    

  nav ul.opt li, nav ul.lang li {
    font-size:1rem;
    padding: 0;
    margin:0.2rem 0.35rem;
    width:auto;
    color: var(--sec-color);
    
  }
  nav ul.lang li.active{
    color: var(--pr-color);
    text-decoration:unset;
  
  }
  .profile-image{
    
    margin-right:0;
  }

  .user{
    font-size: 1.3rem;
    color:var(--sec-color);
    padding-bottom: 0.1rem;
  }
  .bars, .exit{
    display: none;
  }
  nav ul li:hover {
    color: var(--pr-color);
    background-color: unset;
  }
  header{
    border-bottom: 1px solid var(--pr-color);
    padding-bottom: 1rem;
  }
  .lnk {
    font-size:1rem;
    padding: unset;
  }
  .lnk:hover {
    color: var(--pr-color);
  }
  
  nav ul a:hover {
    background-color: unset;
  }

  /*
    .social, .design{
        width:25%;
        margin-left: auto;
    }
    .social{
        justify-content: end;
    }
    .design {
        text-align: right;
        padding-right: 0.5rem;
    }

    */

  
}

