.user-title{
    color:var(--txt-55-color);
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.75rem 0;
    box-sizing: border-box;
    border-right:1rem solid transparent;
    margin: 0 auto;
    width:80%;
    text-decoration:transparent;
    text-decoration-thickness: 0.2rem;
   
    
}

/*.inact-usr-frm,
.act-usr-frm,
.pending-usr-frm {
    color:#fff;
}*/
.inact-usr-frm{
    /* color: #e4002b; */
    text-decoration:underline;
    border-right-color:#e4002b;
    text-decoration-color: #e4002b;
    text-decoration-thickness: 0.2rem;
}
.act-usr-frm{
    /* color: #84bd00; */
    text-decoration:underline;
    border-right-color:#597211;
    text-decoration-color: #597211;
    text-decoration-thickness: 0.2rem;
   
}
.pending-usr-frm{
    /* color: #F3894B; */
    text-decoration:underline;
    border-right-color:#F3894B;
    text-decoration-color: #F3894B;
    text-decoration-thickness: 0.2rem;
}
.form-container{
    display:flex;
    justify-content:space-between;
    flex-direction: column;
    flex-wrap: wrap;
    gap:1rem;
}
.form-container > div{
    flex:1;
    box-sizing: border-box;
    /* border:1px solid #000; */
    margin: 0 auto;
    width:80%;
    
}

input.user-form{
    width: 100%;
    
}
.user-form-pass{
    width: calc(100% - 3.3rem);
}


.user-form-date{
     width: calc(100% - 6rem); 
}
button.link-like-btn{
    background-color: transparent;
    color:var(--sec-color);
    padding: 0.5rem ;
    font-weight: normal;
    font-size:1rem;
    border:1px solid transparent; 
    border-radius: 0;
    position:relative;
    width:auto;
    margin-left: 1rem;
    cursor: pointer;
}
button.link-like-btn:hover{
    border-top-color: var(--sec-color);
    border-left-color: var(--sec-color);
    border-bottom-color: var(--pr-color);
    border-right-color: var(--pr-color);
}
button.link-like-btn:active{
    color:var(--pr-color);
    border-top-color: var(--pr-color);
    border-left-color: var(--pr-color);
    border-bottom-color: var(--sec-color);
    border-right-color: var(--sec-color);
    top:1px;
}


input.remark{
    background-color: var(--sec-color);
    color:#fff;
}
label{
    display:block;
}
.created-pass{
    min-height: 1.5rem;
    font-size: 1.5rem;
    font-weight: normal;
    display:inline-block;
    width: calc(100% - 6.2rem);
    color:var(--pr-color);
}
.frm-eye-icon, .frm-key-icon{
    font-size:1.2rem;
    color:var(--sec-color);
    padding: 0.4rem;
    position:relative;
    top:0.5rem;
    margin-left:0.5rem;
    cursor:pointer;
    border:2px solid transparent;
    
}
.frm-key-icon{
    top:-0.25rem;
    padding: 0.2rem 0.5rem;
    font-size:1rem;
}

.frm-eye-icon:hover, .frm-key-icon:hover{
    border-left-color: var(--sec-color);
    border-top-color: var(--sec-color);
    border-right-color: var(--pr-color);
    border-bottom-color: var(--pr-color);
}
.frm-eye-icon:active, .frm-key-icon:active{
    top:0.55rem;
    color:var(--pr-color);
    border-left-color: var(--pr-color);
    border-top-color: var(--pr-color);
    border-right-color: var(--sec-color);
    border-bottom-color: var(--sec-color);
}
.frm-key-icon:active{
    top:-0.2rem;
}
.slider-range{
    width: calc(100% - 6rem);
}

.slider-range-label-inline{
    display:inline-block;
    /* border: 1px solid var(--txt-55-color); */
    position: relative;
    top: -0.5rem;
    padding: 0 0.5rem;
    text-align: center;
    width:22px;
}
.pass-tool{
    margin-bottom: 0.7rem;
    background-color: whitesmoke;
}

ul.mail-opt-list{
    margin-top: 2.5rem;
    list-style-type: none;
    padding: 0;
}


/* tablet */
@media screen and (min-width: 768px) {

    .user-title{
        margin:0;
        width:100%;
        
    }

    .form-container{
        flex-direction: row;
    }
    .form-container > div{
        flex:1 1 47%;
        
    }
}



/* desktop */
@media screen and (min-width: 1000px) {
    .form-container{
        flex-direction: row;
    }

    .form-container > div{
        flex:1 1 30%;
       
    }

}
