
.phone{
    color:var(--txt-55-color);
    font-weight: normal;
}
.site-link{
    text-decoration: none;
    font-weight: normal;
    color:var(--sec-color);
}
.site-link:hover{
    text-decoration: underline;
    color:var(--pr-color);
}