
.carusel, .file-uploader{
    width:100%;
    margin-bottom: 1rem;
  }    

  h2{
    font-family: 'Roboto';
    font-size: 22.78pt;
    font-weight: 500;
    color:var(--pr-color);
    margin :1rem 0 0.5rem 0;
  }

  h3{
    font-family: 'Roboto';
    font-size: 18pt;
    font-weight: bold;
    color:var(--txt-55-color);
    margin: 0.5rem 0 1rem 0;
  }



  article{
    margin-bottom:2.5rem ;
  }

  article h3{
    color:var(--txt-70-color);
    margin:0;
    margin-bottom: 0.75rem;

  }
  article p {
    color:var(--txt-dd-color);
    font-size: 16pt;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 0 1rem 0 ;
    text-align: justify;
  }

  .main-content, .main-content-admin{
    display:flex;
    flex-direction: column;
  }
  .t-L{display: none;}
  .t-s{display: block;}

  .sel-part {
    margin:0
}



  @media screen and (min-width: 1000px) {
   .carusel, .file-uploader{
        width:45%;
    }    
    /* */
    .carusel{
      width:70%;
      max-width: 950px;
    }    

    .main-content, .main-content-admin{
        display:flex;
        /*flex-direction: row;*/
        flex-direction: column;
        justify-content: space-between;
        margin-top: 2rem;
        /* */
        align-items: center;

    }
    .main-content-admin{
      align-items:flex-start;
      flex-direction: row;
      justify-content: space-between;
    }


    .article-container{
    /*width: 50%;*/
    width:70%;
    max-width: 950px;
    }
    .article-container-admin{
      width: 50%;
      
      }
    .t-L{display: block;}
    .t-s{display: none;}
    h2, h3 { 
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 22.78px;
    }
    h3 {
        margin-bottom: 2rem;
        font-size: 18pt;
        font-weight: bold;
    }
    
  }